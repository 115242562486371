html {
  position: fixed;
}

body {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
  margin: 0px;
  background: #aaaaaa;
}

.side {
  position: absolute;
  background: white;
  font-size: 40px;
  z-index: 999;
  width: 250px;
  height: 100%;
}

.side-panel {
  position: relative;
  left: 0px;
  top: 0px;
  width: 300px;
  height: 100%;
  z-index: 999;
}

.search-box {
  position: absolute;
  left: 20px;
  top: 20px;
}

/* arrow */
#arrow {
  position: absolute;
  display: block;
  cursor: pointer;
}

#arrow.south {
  bottom: 10px;
  left: 50%;
}

#arrow.north {
  top: 10px;
  left: 50%;
}

#arrow.east {
  top: 50%;
  right: 10px;
}

#arrow.west {
  top: 50%;
  left: 10px;
}

.round {
  /*position: absolute;*/
  border: 0px solid #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.south .round {
  transform: rotate(90deg) scale(2);
  /* bottom: 10px;*/
}

.north .round {
  transform: rotate(270deg) scale(2);
  top: 10px;
}

.west .round {
  transform: rotate(180deg) scale(2);
  left: 10px;
}

.east .round {
  transform: rotate(0deg) scale(2);
  right: 10px;
}

#cta {
  width: 100%;
  position: absolute;
}

#cta .arrow {
  left: 30%;
}
.arrow {
  position: absolute;
  bottom: 0;
  margin-left: 0px;
  width: 12px;
  height: 12px;
  background-size: contain;
  top: 15px;
}
.segunda {
  margin-left: 8px;
}
.next {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

/* Customize CSS of leaflet copyright */
a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  margin-right: 5px;
}

a:hover {
  color: #1a73e8;
  text-decoration: none;
}

.leaflet-control {
  animation-name: slideInFromBottom;
  animation-duration: 1s;
}

.leaflet-control-attribution {
  line-height: 13px;
  float: right;
  /* padding-left: 3px; */
  margin-right: 15px;
  display: table-row;
  font-size: 10px;
  /* background: #fff; */
  overflow: hidden;
  bottom: 0;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  border: 0;
  color: #333;
}

.leaflet-bar {
  /* box-shadow: 0 1px 5px rgb(0 0 0 / 65%); */
  box-shadow: 0 0px 0px rgb(0 0 0 / 0%);
  border-radius: 4px;
}

.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  margin-bottom: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 0px;
  box-shadow: 0 0px 0px rgb(0 0 0 / 0%);
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.arrow.primera.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.round .arrow {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.round .arrow.primera {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.greenstand-cluster:hover {
  z-index: 999 !important;
}

.greenstand-cluster.clicked {
  z-index: 999 !important;
}

.greenstand-cluster-box-small {
  background: url(./images/cluster_46px.png);
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
}

.greenstand-cluster-box-small:hover {
  background: url(./images/cluster_46px_highlight.png);
  z-index: 9;
}

.greenstand-cluster.clicked .greenstand-cluster-box-small {
  background: url(./images/cluster_46px_clicked.png);
}

.greenstand-cluster-box-large {
  background: url(./images/cluster_63px.png);
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
}

.greenstand-cluster-box-large:hover {
  background: url(./images/cluster_63px_highlight.png);
  z-index: 9;
}

.greenstand-cluster.clicked .greenstand-cluster-box-large {
  background: url(./images/cluster_63px_clicked.png);
}

.greenstand-point:hover {
  z-index: 999 !important;
}

.greenstand-point.clicked {
  z-index: 999 !important;
}

.greenstand-point-box {
  background: url(./images/pin_29px.png);
  width: 29px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*transform: translate(-50%, -50%);*/
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
}

.greenstand-point-box:hover {
  background: url(./images/pin_29px_highlight.png);
}

.greenstand-point.clicked .greenstand-point-box {
  background: url(./images/pin_32px.png);
}

.greenstand-point-highlight {
  z-index: 999 !important;
}

.greenstand-point-highlight-box {
  background: url(./images/pin_29px_highlight.png);
  width: 29px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*transform: translate(-50%, -50%);*/
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
}

.greenstand-point-selected {
  z-index: 9999 !important;
}

.greenstand-point-selected-box {
  background: url(./images/pin_32px_highlight.png);
  width: 29px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*transform: translate(-50%, -50%);*/
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
}

/* new version */

.greenstand-cluster-highlight {
  z-index: 999 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.greenstand-cluster-highlight-box {
  background: url(./images/cluster_63px_highlight.png);
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
}

.greenstand-cluster-highlight-box.small {
  background: url(./images/cluster_46px_highlight.png);
  width: 46px;
  height: 46px;
}

.debug-icon {
  background: red;
  border-radius: 50%;
}

.text {
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
}
